<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold">Referral Wallets</h2>
      </div>
      <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, status, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :url="url"
        :ajax="true"
        :ajaxPagination="true"
        :index="true"
        :reverse-index="true"
        :columns="referralsColumns"
        :pageDetails="true"
        :fillable="true"
        :query="searchQuery"
        :loading="loading"
        :onClick="click"
        dropdown="actions"
        ref="table"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${this.$baseurl}/admin/personal/users/referrals-details`,
      loading: false,
      searchQuery: "",
      referralsColumns: [
        {
          name: "full_name",
          th: "Name",
          render: (referrals) => {
            return referrals?.full_name;
          },
        },
        {
          name: "referrals",
          th: "Number Referred",
          render: (referrals) => {
            return referrals.referrals?.length;
          },
        },
        {
          name: "wallet",
          th: "Wallet Balance",
          render: (referrals) => {
            return this.$options.filters.currency(referrals.referral_wallet?.balance || 0);
          },
        },
        {
          name: "wallet",
          th: "Wallet Status",
          render: (referrals) => {
            switch (referrals.referral_wallet?.status) {
              case "active":
                return `<div class="badge badge-green-soft-outline">
                    Active
                  </div>`;
              case "inactive  ":
                return `<div class="badge badge-red-soft">
                    Inactive
                  </div>`;
              default:
                return `<div class="badge badge-orange-soft-outline">
                    No wallet
                  </div>`;
            }
          },
        }
      ],
    };
  },
  methods: {
    click(selected) {
      return this.$router.push({
        name: "staff-view",
        params: {
          staffId: selected?.id,
          accountType: this.accountType,
        },
      });
    },
  },
};
</script>
